export const loadingFunc = (el, binding) => {
  //check for case directive run many times
  if (!el.classList.contains('add_loading_directive')) {
    const div = document.createElement('div')
    div.classList.add('loading_spin')
    div.innerHTML =
      '<div class="circular_box"><svg class="circular" viewBox="25 25 50 50"> <circle class="path" cx="50" cy="50" r="20" fill="none"></circle></svg>Đang tải</div>'
    el.appendChild(div)
  }
  if (binding.value) {
    el.classList.add('loading')
  } else {
    el.classList.remove('loading')
  }
  el.classList.add('add_loading_directive')
}

export const tooltipFunc = (el, binding) => {
  el.style.position = 'relative'
  if (el?.querySelector('.tooltip_cont')) {
    return
  }
  const div = document.createElement('div')
  const tooltipText = document.createElement('div')
  div.classList.add('tooltip_cont')
  tooltipText.classList.add('tooltip_text')
  tooltipText.style.minWidth = binding.value.minWidth || 'unset'
  tooltipText.innerHTML = binding.value.text
  div.appendChild(tooltipText)
  el.classList.add('use_tooltip')
  el.appendChild(div)
}

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

export const indexToLetter = (index) => {
  let letterList = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J']
  return letterList[index]
}

/**
 * Represents a book.
 * @constructor
 * @param {string} dataurl - url download.
 * @param {string} filename - Name file.
 */
export const downloadFile = (dataurl, filename) => {
  const link = document.createElement('a')
  link.href = dataurl
  link.download = filename
  link.click()
}

/**
 * break work.
 * @constructor
 * @param {string} text - Name file.
 */
export const breakWord = (text, length = 15) => {
  const myArray = text.split(' ')
  const textConvert = myArray.map((phrase) => {
    if (phrase.length > length) {
      return phrase.slice(0, 15) + '-' + phrase.slice(15)
    } else {
      return phrase
    }
  })
  return textConvert.join(' ')
}

export const requestNotiPermission = () => {
  window?.Notification &&
    Notification.permission === 'default' &&
    Notification.requestPermission()
}

const initJpush = () => {
  const notiPush = window?.MTpushInterface
  const isLogin = localStorage.getItem('adminInfo')
  let browserId
  if (isLogin) {
    browserId = localStorage.getItem('browserId')
  } else {
    browserId = crypto.randomUUID()
    localStorage.setItem('browserId', browserId)
  }
  notiPush.init({
    report_url: process.env.VUE_APP_JPUSH_REPORT_URL,
    appkey: process.env.VUE_APP_JPUSH_APPKEY,
    user_str: browserId,
    fail(data) {
      console.log('Failed to create online push', data)
    },
    success: (data) => {
      try {
        console.log(
          'Online push created successfully notication',
          data,
          notiPush?.getRegistrationID(),
        )
        if (notiPush?.getRegistrationID()) {
          //TODO: LOGIN WHEN INIT SUCCESS
          localStorage.setItem('regNotiId', notiPush.getRegistrationID())
          requestNotiPermission()
        }
        notiPush.mtPush.onDisconnect(function () {
          console.log('onDisconnect')
        })
        notiPush.onMsgReceive((msgData) => {
          console.log('data received:', msgData)
        })
      } catch (error) {
        console.log(error)
      }
    },
    webPushcallback: (cod, tip) => {
      console.log('The status code and prompt that the user gets', cod, tip)
    },
    baseUrl: process.env.VUE_APP_JPUSH_BASE_URL,
    canGetInfo: (d) => {
      try {
        console.log('getRegId', notiPush.getRegistrationID(), d)
      } catch (error) {
        console.log(error)
      }
    },
  })
}

export const initWebPush = () => {
  if (window.document.readyState === 'complete') initJpush()
  else
    window.addEventListener('load', () => {
      initJpush()
    })
}

export const sumArray = (arr) => {
  if (!Array.isArray(arr) || !arr.length) return 0
  return arr.reduce((currentVal, currentItem) => {
    return currentVal + (Number(currentItem) || 0)
  }, 0)
}

export const deepClone = (data) => JSON.parse(JSON.stringify(data))

export const roundNum = (num, length = 1) => {
  return String(Math.round(num * Math.pow(10, length)) / Math.pow(10, length))
}

export const flatObj = (obj, key) => {
  let temp = []
  temp.push(obj[key])
  if (obj.childList && obj.childList.length) {
    obj.childList.forEach((item) => {
      temp = [...temp, ...flatObj(item, key)]
    })
  }
  return temp
}

export const endStatementIndex = (index, list) => {
  let indexTo = index
  let statementTo = list[index].commonSub - 1
  do {
    statementTo += (list[indexTo]?.question || list[indexTo])?.type === 'synthesize' ? (list[indexTo]?.question || list[indexTo])?.questionContent.questionList?.length : 1
    indexTo++
  } while (list[indexTo] && list[indexTo].statementCurrent == list[indexTo - 1].statementCurrent)
  return statementTo
}

